import * as Accordion from '@radix-ui/react-accordion';

import { Icon } from '@dandapani/web/ui/icons';

export interface FaqAccordionsProps {
  data: { question: string; answer: string }[];
}

export function FaqAccordions({ data }: FaqAccordionsProps) {
  return (
    <Accordion.Root type="single" orientation="horizontal" asChild>
      <ul className="border-b-2 border-t-2 border-solid border-gray-900/30">
        {data.map(({ question, answer }, index) => (
          <Accordion.Item asChild value={index.toString()} key={index.toString() + question}>
            <li className="overflow-hidden border-b-2 border-solid border-gray-900/30 py-8 last:border-none">
              <Accordion.Trigger className="flex w-full justify-between gap-2 [&[data-state=open]_svg]:rotate-[270deg]">
                <span className="block w-full text-left text-lg font-medium transition-colors md:w-1/2 lg:text-1.5">
                  {question}
                </span>
                <Icon className="mt-0.5 h-4 w-4 text-red-500 transition-transform" icon="chevron" dir="bottom" />
              </Accordion.Trigger>
              <Accordion.Content className="overflow-hidden data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down">
                <p className="whitespace-pre-line pt-4 text-lg md:ml-auto md:w-1/2 md:pt-2">{answer}</p>
              </Accordion.Content>
            </li>
          </Accordion.Item>
        ))}
      </ul>
    </Accordion.Root>
  );
}
